import React, { useEffect, useRef, useState } from 'react';
import css from './RecentlyAdded.module.css';

import CustomListingCardComponent from './CustomListingCard';
import classNames from 'classnames';
//////
const RecentlyAddedComponent = props =>{

    const {entities,
            handleAddToCart,
            handleAddToWishlist,
            wishlistId,
            createWishlistInProgress,
            createWishlistError,
    } = props;
    const {listing,image,stock} = entities;
    
    const view = 
                    <>
                       
                       <div className={css.recent}> 
                            <div>
                                <h2 className={classNames(css.textWhite,css.textMargin)}>
                                    Recently Added
                                </h2>
                            </div>
                            <div className={css.row}>

                                { listing !== undefined? Object.values(listing).map((data,key)=>{ 
                                    const id  = data?.id?.uuid;
                                    const imgId = listing[id]?.relationships?.images?.data[0]?.id?.uuid;
                                    let img = "";
                                    let stockId = "";
                                    if(imgId !== undefined){
                                         img = image[imgId]?.attributes?.variants['my-variant']?.url;
                                         stockId = listing[id]?.relationships?.currentStock?.data?.id?.uuid;
                                    }
                                    

                                    const stockCount = stockId? stock[stockId]?.attributes?.quantity:0;

                                    return(
                                        <CustomListingCardComponent 
                                            data={data} 
                                            img={img} 
                                            stockCount = {stockCount}
                                            handleAddToCart={handleAddToCart}
                                            handleAddToWishlist={handleAddToWishlist}
                                            wishlistId={wishlistId}
                                            createWishlistInProgress={createWishlistInProgress}
                                            createWishlistError={createWishlistError}
                                        />
                                    )
                                    
                                }):""}

                               
                            </div>

                           

                            <div className={css.brands}>
                               
                                <p>Choose from our selection of dance brands!</p>
                                <h4>
                                    Brands
                                </h4>
                                <hr/>
                            </div>
                        </div>
                       
                       
                    </>
    
  return (
    
    <>
    {view}
    </>
    
    
  );
};


export default RecentlyAddedComponent;
