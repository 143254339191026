import React, { useEffect, useRef, useState } from 'react';
import css from './BaseControl.module.css';

import classNames from 'classnames';
import home from '../../assets/home.png';
import heart from '../../assets/heart.png';
import cart from '../../assets/cart.png';
import profile from '../../assets/profile.png';
import NamedLink from '../NamedLink/NamedLink';

const BaseControlComponent = props =>{
    const activeClassName = 'my-active-class';
    

  return (
    
    <div className={css.baseControl}>
      
        <NamedLink name="LoginPage">
        <div className={css.iconCol}>
          <img src={home} />
          <p>Shop</p>
          </div>
        </NamedLink>

        <NamedLink name="LoginPage">
        <div className={css.iconCol}>
          <img src={heart} />
          <p>Wishlist</p>
          </div>
        </NamedLink>

        <NamedLink name="LoginPage">
        <div className={css.iconCol}>
          <img src={cart} />
          <p>Cart</p>
          </div>
        </NamedLink>

        <NamedLink name="LoginPage">
        <div className={css.iconCol}>
          <img src={profile} />
          <p>My Account</p>
          </div>
        </NamedLink>

    </div>
    
  );
};




export default BaseControlComponent;
