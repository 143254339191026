import React from 'react';
import css from './CustomFooter.module.css';
import NamedLink from '../NamedLink/NamedLink';
import BaseControlComponent from './BaseControl';

function CustomFooterComponent(){
    const activeClassName = 'my-active-class';
    
    const landingPageProps = {
        name: 'LandingPage',
        activeClassName,
        match: { url: '/' },
      };

  return (
        <>
            <div className={css.footer}>
              <div>
                OUR SERVICES
                <ul>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        About Us
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                      How It Works
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Order History
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Dashboard
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        My Orders
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Order Tracking
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Store List
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Wishlist
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div>
                USEFUL LINKS
                <ul>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Privacy Policy
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Cokies Policy
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Disclaimer
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        End User License Agreement
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Return Policy
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Terms & Conditions
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Affiliate Dashboard
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink className={css.playbtn} name="LoginPage">
                        Become an Affiliate
                    </NamedLink>
                  </li>
                </ul>
              </div>
              
            </div>

            <BaseControlComponent />

        </>
  );
};


export default CustomFooterComponent;
