import React, { useEffect, useRef, useState } from 'react';
import css from './SocialContent.module.css';

import classNames from 'classnames';
import CustomFooterComponent from './CustomFooter';
import MenuIcon from '../../containers/TopbarContainer/Topbar/MenuIcon';
import Button from '../Button/Button';
import s1 from '../../assets/s1.jpg';
import s2 from '../../assets/s2.jpg';
import s3 from '../../assets/s3.jpg';
import s4 from '../../assets/s4.jpg';
import s5 from '../../assets/s5.jpg';
import s6 from '../../assets/s6.jpg';
import s7 from '../../assets/s7.jpg';
import s8 from '../../assets/s8.jpg';

import s9 from '../../assets/s9.jpg';
import s10 from '../../assets/s10.jpg';
import s11 from '../../assets/s11.jpg';
import s12 from '../../assets/s12.jpg';
import playbtn from '../../assets/playbtn.png';
import logo from '../../assets/dx_logo2.png';

import NamedLink from '../NamedLink/NamedLink';
import ExternalLink from '../ExternalLink/ExternalLink';
//

const SocialComponent = props =>{
    const activeClassName = 'my-active-class';
    
    const landingPageProps = {
        name: 'SearchPage',
        activeClassName,
        match: { url: '/s' },
      };

    const {blocks} = props;

    const view =  <>
                     
                       <div className={css.socialCon}>
                        
                            <div className={css.headingLogo}>
                                <div className={css.overlayLogo}>
                                    <img src={logo} />
                                    <div className={css.absImg}>
                                    <svg className={css.svg} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                                    </div>
                                </div>
                                
                                <h3>
                                    buyselldance
                                </h3>
                            </div>

                            <div className={css.row}>

                                {
                                    blocks.map((block,key)=>{
                                        const imgSrc = block.media.image.attributes.variants["original1200"].url;

                                        return(
                                            <div key={key} className={css.imgCon}> 
                                                <img className={css.resize} src={imgSrc} />
                                                <div className={css.overly}></div>
                                                <ExternalLink className={css.playbtn} href={"https://www.instagram.com/reel/C_mPhtQsc97/"}>
                                                    <img   src={playbtn} />
                                                </ExternalLink>
                                            </div>
                                        )

                                    })
                                }
                                
                                    

                            </div>

                            <div className={css.flexRow}>
                                
                                  
                                        <NamedLink className={css.btnBlack}  name="LoginPage">
                                            Load More
                                        </NamedLink>
                                   
                                   <div className={css.iconBtn}>
                                        
                                        <svg className={css.svg} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                                        <NamedLink  name="LoginPage">
                                                Follow on Instagram
                                                
                                        </NamedLink>
                                   </div>
                                        
                                   
                                     
                            </div>

                          
                        
                        </div>
                       
                    </>
                    
                    

  return (
    
    <>
        {view}
    </>
    
    
  );
};



export default SocialComponent;
